<template>
  <div>
    <div class="seatop-mian ">
      <div>
        <template v-if="language=='zh'">
          <myimage :url='url'></myimage>
        </template>
        <template v-if="language=='TW'">
          <myimage :url='urlTW'></myimage>
        </template>
        <template v-if="language=='En'">
          <myimage :url='urlEn'></myimage>
        </template>

      </div>
      <div
          :class="screenWidth>992?'seamain-service hidden-sm-and-down':'seamain-service paddbot50 hidden-xs-only hidden-md-and-up' ">
        <div class="sea-dry">
          <div class="seachina-text">{{$t('overseasstudyDetail.首页')}} >{{$t('overseasstudyDetail.港漂干货')}} >{{$t('overseasstudyDetail.香港留学')}}</div>
          <div class="seaenglish-text">{{$t('overseasstudyDetail.PortromptType')}}</div>
          <div class="seacon">
            <div class="seacon-toptitle">{{SchoolPresentDetils.title}}</div>
            <div class="seacon-toptime">{{$t('overseasstudyDetail.发布时间')}}{{SchoolPresentDetils.createDate}}  {{$t('overseasstudyDetail.热度')}}{{SchoolPresentDetils.viewsCount||0}}</div>
            <div class="seacon-image" v-html="SchoolPresentDetils.content"></div>
          </div>
          <div class="flexrow online-img flexjustifycontent" @click="toonline">
            <div><img src="../../assets/image/weixin.png" alt=""></div>
            <div>{{$t('overseasstudyDetail.在线咨询')}}</div>
          </div>
        </div>
      </div>
      <!-- 移动端 -->
      <div class="hidden-sm-and-up seamain-service-sm">
        <div class="sea-dry-sm">
          <div class="seachina-text-sm">{{$t('overseasstudyDetail.首页')}} > {{$t('overseasstudyDetail.港漂干货')}}>{{$t('overseasstudyDetail.香港留学')}}</div>
          <div class="seaenglish-text-sm">{{$t('overseasstudyDetail.PortromptType')}}</div>
          <div class="seacon-sm">
            <div class="seacon-toptitle-sm">{{SchoolPresentDetils.title}}</div>
            <div class="seacon-toptime-sm">{{$t('overseasstudyDetail.发布时间')}}{{SchoolPresentDetils.createDate}} {{$t('overseasstudyDetail.热度')}}{{SchoolPresentDetils.viewsCount||0}}</div>
            <div class="seacon-image-sm"  v-html="SchoolPresentDetils.content" ></div>
          </div>
          <div class="flexrow online-img-sm flexjustifycontent" @click="toonline">
            <img src="@/assets/phone/overseasStudy/online.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <erweimaDialog ref="ewmdialog"></erweimaDialog>
  </div>
</template>

<script>
import myimage from '@/components/myimage'
import erweimaDialog from '@/components/erweimaDialog'
import "element-ui/lib/theme-chalk/display.css";
import {getSchoolPresentDetils} from '@/api/index.js'

export default {
  components: {
    myimage,
    erweimaDialog
  },
  data() {
    return {
      url:require('../../assets/phone/tu1.png'),
      urlTW:require('../../assets/phone/tu1TW.png'),
      urlEn:require('../../assets/phone/tu1TW.png'),
      bannerList: [
        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e',
        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e',
        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e'
      ],
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      HKproblemList: [
        {content: '香港宣道国际学校口碑怎么样？', time: '04/09', hot: '13220'},
        {content: '香港宣道国际学校口碑怎么样？', time: '04/09', hot: '13220'},
        {content: '香港宣道国际学校口碑怎么样？', time: '04/09', hot: '13220'},
        {content: '香港宣道国际学校口碑怎么样？', time: '04/09', hot: '13220'},
      ],
      presentId:'',
      SchoolPresentDetils:{},
      language:'zh'
    }
  },
  mounted() {
    this.language=localStorage.getItem('language')
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
        if (document.body.clientWidth <= 768) {
                 location.reload();
        }
      })();
    };
  },
  created(){
    this.presentId = this.$route.query.presentId
    this._getSchoolPresentDetils()
  },
  methods:{
    toonline(){
      this.$refs.ewmdialog.dialogVisibletwo=true
    },
    _getSchoolPresentDetils(){
      getSchoolPresentDetils({
        presentId:this.presentId
      }).then(res=>{
        this.SchoolPresentDetils=res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.seatop-mian {
  width: 100vw;
}

.seamain-service {
  width:1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}

.seamain-service-sm {
  width:335PX;
  margin: 0 auto;
}
.paddbot50{
  padding-bottom: 50PX;
}
.seamain-service > div, .seamain-service-sm > div {
  position: relative;
  top: 0;
}

.seachina-text {
  font-size: 20PX;
  font-weight: 600;
}

.seaenglish-text {
  padding-bottom: 24px;
  font-size: 28PX;
  border-bottom: 4PX solid #FADBBA;
  color: #999999;
  margin-bottom: 50px;
}

.sea-dry {
  padding: 150px 0 70px 0;
  text-align: left;
}

.seacon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.seacon-toptitle {
  font-size: 32PX;
  font-weight: bold;
}

.seacon-toptime {
  font-size: 16PX;
  color: #999999;
  margin-top: 10PX;
}

.seacon-image {
  width: 100%;
  //height: 828px;
  /* background: #999999; */
  margin-top: 24px;
::v-deep{
img{
  max-width:100%;
  text-align: center!important;
  margin: 0 auto;
  display: flex;
}
}
}

.online-img {
  background: #FE992B;
  color: #fff;
  font-size: 20PX;
  border-radius: 33PX;
  height: 65PX;
  margin-top: 50PX;
  width: 203PX;
  text-align: center;
  margin: 50PX auto;
}

.online-img img {
  width: 40PX;
  height: 40PX;
  margin-right: 16PX;
}


/* 移动端 */
.sea-dry-sm {
  padding: 20PX 0;
  text-align: left;
}

.seachina-text-sm {
  font-size: 18PX;
  font-weight: 600;
}

.seaenglish-text-sm {
  padding-bottom: 24px;
  font-size: 16PX;
  border-bottom: 2PX solid #FADBBA;
  color: #999999;
  margin-bottom:20PX;
}


.seacon-sm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.seacon-toptitle-sm {
  font-size:16PX;
  font-weight: bold;
}

.seacon-toptime-sm {
  font-size: 12PX;
  color: #999999;
  margin-top: 10PX;
}

.seacon-image-sm {
  width: 100%;
  height:244PX;
  /* background: #999999; */
  margin-top: 24PX;
::v-deep{
img{
  max-width:100%;
  text-align: center!important;
  margin: 0 auto;
  display: flex;
}
}
}

.online-img-sm img {
  width: 150PX;
  height: 48PX;
  margin: 16PX 0;
}

</style>